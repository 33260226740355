.field{
  margin-top: 0.8rem;
  display: grid;
  align-self: start;
  grid-template-columns: max-content 1fr;
  &[hidden]{
    display: none;
  }
}
.field label {
  @extend h4;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  padding-right: 1em;
  align-self: end;
  line-height: 0.8em;
}

.field[type="headline"]{
  margin-top: 2.2rem;
}

.field input[type="text"],
.field input[type="email"],
.field input[type="textarea"],
.field textarea,
.field select,
.field .StripeElement {
  @extend .text-xs;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  background-color: var(--color-white);
  resize: none;
  line-height: 1em;
}

.field[data-type="textarea"] {
  border-bottom: 1px solid;
  label {
    align-self: end;
  }
  textarea {
    border-bottom: none;
    margin-top: 0.6em;
    min-height: 200px;
    grid-column: span 2;
  }
  .spacer{
    border-bottom: 1px solid;
    &:after{
      content: "\00a0";
    }
  }
}

  .field[data-type="radio"],
  .field[data-type="checkbox"] {
    display: flex;
    align-items: start;
  }
  .field[data-type="radio"] label,
  .field[data-type="checkbox"] label {
    font-weight: 400;
  }
  .field[data-type="radio"] input,
  .field[data-type="checkbox"] input {
    height: calc(1rem * var(--line-height));
    margin-right: var(--spacing-s);
    flex-shrink: 0;
  }

  .field .error {
    margin-top: var(--spacing-xs);
    font-size: var(--text-xs);
    grid-column: span 2;
  }
