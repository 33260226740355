main[data-id="harvest"] {
  .content-wrapper {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}


.harvest .gallery-grid{
  padding: 0;
  @include mq(md){
    padding: 0 5.5555555556vw;
  }
  .gallery-item{
    grid-column: span 6;
    margin-bottom: 6.5vw;
    padding: 0 5.5555555556vw;
  }
  picture.portrait{
    grid-column: span 6;
    padding: 0;
    height: 100%;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
}

.gallery-item a{
  display: block;
  margin-top: 0.8rem !important;
  &:hover{
    @extend .color-red;
  }
}

.harvest .archives-grid .archives-item{
  align-items: center;
  flex: 1;
  .archives-item-title{
    flex: none;
    &:first-of-type{
      margin-bottom: 1.3em;
    }
  }
  a{
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.harvest .archives-grid .archives-item{
  grid-column: span 6;
  padding: 0 5.5555555556vw;
}

.harvest .archives-grid .archives-item .portrait{
  @include mq(md){
    padding: 0 3vw;
  }
}
