i[class^="icon-"]:before, i[class*=" icon-"]:before {
  @include font(icons);
  @include fs(icons);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon{
  @include font(icons);
  @include fs(icons);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\f101";
}
.icon-arrow-up:before {
  content: "\f102";
}
.icon-arrow-y:before {
  content: "\f103";
}
.icon-event-dot:before {
  content: "\f104";
}
.icon-smile:before {
  content: "\f105";
}

