.details-cart {
  position: relative;
  z-index: 1;
}
.details-cart .toggle-cart {
  list-style: none;
  cursor: pointer;
  text-align: right;
}
.details-cart .toggle-cart:focus {
  outline: 0;
}

/* Mocks .details-cart summary */


.cart {
  width: 100%;
  font-feature-settings: "lnum";
  @include mq(md){
    width: 25%;
    min-width: 325px;
    opacity:1;
  }
}

.cart[data-theme="dark"] {
  padding: var(--spacing-m);
}

.cart[data-theme="light"] {
  padding: 1.25rem;
  padding-top: 0.55rem;
  @include mq(md){
    padding: 1.875rem;
    padding-top: 0.925rem;

  }
  color: var(--color-black);
  background-color: white;
  border-left: 1px solid black;
}
.cart[data-variant="checkout"]{
  width: 100%;
}
.cart[data-variant="checkout"] .close-cart{
  display: none;
}

.cart[data-variant="checkout"] .cart-header{
  display: none;
}

.details-cart .cart {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  right: -100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: right 0.3s;
}

.details-cart .cart.open{
  right: 0;
  transition: right 0.3s;
}

.cart table {
  width: 100%;
}
.cart tfoot{
  margin-top: auto;
}
.cart a {
  text-decoration: none;
}
.cart img {
  width: 3rem;
}
.cart table {
  margin-bottom: var(--spacing-m);
}
.cart tr {
  vertical-align: top;
}
.cart th,
.cart td {
  padding: var(--spacing-s);
}
.cart th {
  text-align: left;
}


.cart tbody a {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: auto auto;
  column-gap: var(--spacing-m);
  padding: 0;
}
.cart tbody th img {
  grid-row-end: span 2;
}
.cart tbody th strong,
.cart tbody th small {
  /*grid-column-start: 2;*/
}
.cart tbody th small {
  font-size: inherit;
  color: var(--color-gray-500);
}
.cart tbody th strong {
  display: block;
  min-width: 6rem;
}
.cart td:nth-last-child(1),
.cart td:nth-last-child(2) {
  text-align: start;
}
.cart tfoot th,
.cart tfoot td {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.cart tfoot th {
  text-align: end;
}
.cart .button-white {
  margin-inline-start: auto;
}
.cart-info {
  text-align: center;
}

// NEW CART

.cart{
  flex:1;
}

.cart .table{
  width: 100%;
  max-width: 100%;
  height: 100%;
  grid-template-rows: min-content;
  @include mq(md){
    display: grid;
    width: 100%;
    max-width: 100%;
    height: 100%;
    grid-template-rows: min-content;
  }
}

.checkout-shipping{
  display: none !important;
}

.cart[data-variant="checkout"]{
  .checkout-shipping{
    display: flex !important;
  }
  .cart-shipping{
    display: none !important;
  }

  .t-foot{
    margin-top: 0;
  }
}

.cart-header{
  display: grid;
  padding-left: 50%;
  grid-template-columns: 1fr;
  .cart-icon{
    order: 2;
    margin-top: 1em;
    height: 4.5rem;
    width: 4.5rem;
    position: relative;
    left: -0.25rem;
    background-image: url("../img/cart.svg");
    background-repeat: no-repeat;
  }
  @include mq(md){
    display: grid;
    padding-left: 0;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
    .cart-icon{
      order: 0;
      margin-top: 0;
      height: 2.813rem;
      width: 2.813rem;
    }
  }
}

.close-cart{
  cursor: pointer;
  position: relative;
  padding-top: 0.7em;
  h4{
    line-height: 1.2em;
  }
}

.cart-title{
  margin-top: 1.2rem;
  margin-bottom: 1.1rem;
}

.t-header{
  margin-top: -1.25rem;
  margin-bottom: 4rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid;
  @include mq(md){
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.cart[data-variant="checkout"]{
  .t-header{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.t-item{
  display: grid;
  grid-template-columns: calc(50% + 0.5rem) 50%;
  border-bottom: 1px solid;
  @include mq(md){
    grid-template-columns: repeat(2, 1fr);
  }
}
.t-item .i-name{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 0.6rem;
  .text-xs{
    @include fs(main);
    @include mq(md){
      font-size: var(--text-xs);
    }
  }

}
.t-item .t-row:last-of-type{
  border-bottom: 0;
}

.t-row{
  border-bottom: 1px solid;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.t-foot{
  display: grid;
  grid-template-columns: calc(50% + 0.5rem) 50%;
  align-self: end;
  width: 100%;
  margin-bottom: 0.6rem;
  margin-top: 2rem;
  @include mq(md){
    margin-bottom: 0.6rem;
    margin-top: 0;
    grid-template-columns: repeat(2,1fr);
  }
  @include mq(lg){
    margin-bottom: 0.9rem;
  }
}

.t-foot-header{
  grid-column: span 2;
}

.t-foot .sum, .t-foot .tax{
  grid-column: 2;
}

.t-foot .tax{
  border-bottom: none;
}



// ORDER CART

.link-cart{
  display: none !important;
  @include mq(md){
    display: block !important;
  }
}

.order .cart{
  width: 100%;
}
.cart thead th:first-of-type{
width: 50%;
}

.cart thead tr td, .cart tfoot td{
  width: 16.6666% !important;
}

.order tfoot th, .order tfoot td{
  padding: 0;
}
.order tfoot th{
  padding-right: 1rem;
}

