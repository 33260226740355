.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include mq(md){
    grid-template-columns: repeat(4, 1fr);
  }
}


// CATEGORY SECTION HEADER

main{
  &[data-filter]:not([data-filter="all"]){
    .category-image{
      display: none;
    }
    .category-title{
      display: none;
    }
    @include mq(md){
      .category-image{
        display: block;
      }
      .category-title{
        display: none;
      }
    }
  }

}

.category-grid .category-image {
  @include mq(md) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.category-title{
  display: grid;
  align-items: center;
  justify-content: center;
  @include mq(md){
    display: none;
  }
}

//

.product-grid {
  display: grid;
  grid-template-columns: 1fr;
}


.product-grid-item {
  position: relative;
  font-feature-settings: "lnum";
  background-color: rgba(0,0,0,0.025);
}

.product-grid-item.landscape{
  grid-column-start: span 2;
}

.product-grid-item a {
  text-decoration: none;
}

.product-grid-item .product-grid-item-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  opacity: 1;
  @include mq(md){
    opacity: 0;
  }
  transition: opacity $transition-duration-default $transition-timing-default;
  p {
    overflow: hidden;
  }
  .info-title,.info-category{
    display: none;
    @include mq(md){
      display: block;
    }
  }
  .info-price{
    text-align: left;
    @include mq(md){
      text-align: right;
    }
  }
  .info-stock{
    text-align: right;
  }
}

.product-grid-item:hover .product-grid-item-info {
  opacity: 1;
  transition: opacity $transition-duration-default $transition-timing-default;
}

.product-grid-item-info{
  padding: 0.7em 0.9375em;
}

@include mq(md) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .product-grid .product-grid-item {
    grid-column-end: span 1;
  }
  .product-grid .product-grid-item:first-child {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
}
