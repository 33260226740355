.gallery-grid {
  grid-row-gap: calc(12vw + 1rem * 2);
  margin-bottom: 3rem;
  padding: 0 5.55555555556vw;
  @include mq(md) {
    padding: 0;
    grid-row-gap: 12vw;
    grid-template-columns: repeat(12, 1fr);
  }

  picture{
    display: block;
    margin-bottom: calc((5.55555555556vw + 1.25rem) * 3);
    @include mq(md){
      margin-bottom: 0;
    }
  }

  .portrait {
    grid-column: 4/10;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;

    }
    @include mq(md){
      padding: 0 6vw;
    }
  }

  .landscape {
    grid-column: span 12;
    padding: 0;
    @include mq(md) {
      padding: 0;
      grid-column: 4/10;
    }
  }

  .portrait.duo{
    grid-column: span 6;
  }
}
