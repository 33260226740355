main[data-id="legals"] {
  .content-wrapper {
    grid-column: 5 / 9;
  }
}

main[data-id="legals"] {


.structure-table{
  display: grid;
  margin-bottom: 2.6rem;
}

.structure-table-item{
  display: grid;
  grid-template-columns: 5.5rem 1fr;
  align-items: baseline;
  @include mq(md){
    grid-template-columns: 4rem 1fr;
  }
  p{
    margin-bottom: 0 !important;
  }
}

h4+p{
  margin-bottom: 2.6rem;
}


}
