// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    sm: (
      fs: 1.2rem, // 18px
      lh: 1.33em,
    ),
    md: (
      fs: 0.938rem, // 15px
      lh: 1.33em,
    ),
    lg:(
      fs: 0.938rem,
      lh: 1.33em,
    )
  ),
  h1: (
    sm: (
      fs: 5rem,
      lh: 1.1em,
    ),
    md: (
      fs: 7.188rem,
      lh: 1.1em,
    ),
    lg: (
      fs: 7.188rem,
      lh: 1.1em,
    )
  ),
  h2: (
    sm: (
      fs: 2.5rem,
      lh: 1.1em,
    ),
    md: (
      fs: 2rem,
      lh: 1.1em,
    ),
  ),
  h3: (
    sm: (
      fs: 0.75rem,
      lh: 1.5em,
    ),
    md: (
      fs: 0.583125rem, //9.33px
      lh: 1.5em,
    ),
    lg:(
      fs: 0.55rem, // 15px
      lh: 1.5em,
    )
  ),
  optio: (
    sm: (
      fs: 9.33px,
      lh: 14px,
    ),
    md: (
      fs: 9.33px,
      lh: 14px,
    ),
  ),
  icons: (
    sm: (
      fs: 1.3em,
      lh: 1em,
    ),
    md: (
      fs: 1.3em,
      lh: 1em,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: mercure-regular,
    fallback: "serif",
    withFile: true,
    OpenType: true,
  ),
  mainitalic:(
    name: Mercure-Italic,
    fallback: "serif",
    withFile: true,
    OpenType: true,
  ),
  h1: (
    name: CaslonCustomBruschi,
    fallback: "CaslonCustomBruschi, serif",
    withFile: true,
    OpenType: false,
  ),
  h2: (
    name: mercure-regular,
    fallback: "mercure-regular, serif",
    withFile: true,
    OpenType: true,
  ),
  h3: (
    name: Bruschi,
    fallback: "sans-serif",
    withFile: true,
    OpenType: false,
  ),
  optio: (
    name: Bruschi,
    fallback: "sans-serif",
    withFile: true,
    OpenType: false,
  ),
  icons: (
    name: icons,
    fallback: "icon",
    withFile: true,
    OpenType: false,
  ),
  calendar:(
    name: almanacmt-webfont,
    fallback: "Almanach",
    withFile: true,
    OpenType: false,
  )
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 0px,
  md: 1024px,
  lg: 1800px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG


