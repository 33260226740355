.text-content{
  @include font(main);
  padding: 0 17.5vw;
  margin: 0 auto;

  p:last-of-type{
    margin-bottom: 1em;
  }
  h2 + p{
    margin-bottom: 1em;
  }
  h3 + p{
    margin-bottom: 3em;
  }
  h3,h4{
    margin-bottom: 0.16em;
  }
}


.text-content-small{
  padding: 0 35vw;
}
