.contact-form{
  margin-top: 3rem;
}

.honeypot {
  position: absolute;
  left: -9999px;
}

.contact-form form{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1em;
}

.contact-form form .field{
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-end: span 6;
  @include mq(md){
    grid-column-end: span 3;
  }
}

.contact-form form .field.email{
  grid-column-end: span 6;
}

.contact-form form .field.subject{
  grid-column-end: span 6;
}

.contact-form form .field.text{
  grid-column-end: span 6;
  border-bottom: 1px solid;
}

.contact-form .button{
  grid-column: span 6;
  margin-top: 2em;
  @include mq(md){
    grid-column: span 2;
    max-width: 50%;
  }
}
.contact-form-info{
  grid-column-end: span 4;
  display: flex;
  justify-content: start;
  margin-top: 2em;
  align-items: start;
  flex-direction: column;
  @include mq(md){
    position: relative;
    top: 0.1rem;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;
    gap: 2em;
  }
}

.contact-form{
  .alert.success{
    text-align: center;
    margin-top: 1rem;
  }
}

