.calendar-container{
  background: white;
  margin: 0 auto;
  display: block;
  padding: 0;
  @include mq(md){
    display: grid;
    grid-template-areas: "a a a";
    grid-auto-columns: 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }
}

.calendar-header{
  margin-bottom: 1em;
  margin-top: 1em;
}

.collapsible-calendar{
  &:hover{
    color: inherit;
  }
}

.calendar-inner{
  margin-bottom: 2rem;
  @include mq(md){
    margin-bottom: 0;
  }
}

.calendar-title{
  text-transform: capitalize;
  position: relative;
  div:after{
    @include font(calendar);
    font-size: 0.6em;
    text-transform: initial;
    position: absolute;
    right: 0;
    top: 0.2rem;
    color: var(--color-red);
  }

  &.january div:after{
    content: "v\2009w";
  }
  &.february div:after{
    content: "w\2009x";
  }
  &.march div:after{
    content: "x\2009m";
  }
  &.april div:after{
    content: "m\2009n";
  }
  &.may div:after{
    content: "n\2009o";
  }
  &.june div:after{
    content: "o\2009p";
  }
  &.july div:after{
    content: "p\2009q";
  }
  &.august div:after{
    content: "q\2009r";
  }
  &.september div:after{
    content: "r\2009s";
  }
  &.october div:after{
    content: "s\2009t";
  }
  &.november div:after{
    content: "t\2009u";
  }
  &.december div:after{
    content: "u\2009v";
  }
}

.day{
  display: grid;
  grid-template-areas: "a a a a a";
  grid-auto-columns: max-content min-content min-content 1fr max-content;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: black;
  padding: 0.15rem 0 0.1rem;
  &:last-of-type{
    border-bottom: 1px solid;
  }
}

.saint{
  justify-self: end;
}

.day.event, .day.event-start, .day.event-end{
  border: 1px solid transparent;
}

.day.event-start{
  border-top: 1px solid black;
}

.day-event{
  grid-column: 1/-1;
}

.day .day-name{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 3rem 1rem;
  @include mq(md){
    grid-auto-columns: 5rem 1rem;
  }
}

.day .day-name span:first-of-type{
  display: inline-block;
  width: 2em;
  overflow: hidden;
  word-break: break-all;
  height: 1.248em;
  line-height: 1.248em;
  @include mq(md){
    display: inline;
    width: unset;
    overflow: unset;
    word-break: unset;
    height: unset;
    line-height: unset;
  }
}

.moon-phase{
  margin-right: 0.4rem;
  text-align: right;
  position: relative;
  top: -0.05rem;
}

.day.first-quarter .moon-phase{
  color: var(--color-red);
  &:after{
    @include font(calendar);
    content: "C";
  }
}
.day[data-moon="full"] + .moon-phase{
  color: var(--color-red);
  &:after{
    @include font(calendar);
    content: "D";
  }
}
.day.last-quarter .moon-phase{
  color: var(--color-red);
  &:after{
    @include font(calendar);
    content: "E";
  }
}
.day[data-moon="new"] .moon-phase{
  color: var(--color-red);
  &:after{
    @include font(calendar);
    content: "A";
  }
}

// SOLSTICE

.solistice{
  margin-left: 1.2rem;
  @include mq(md){
    margin-left: 0.5rem;
  }

}

html[lang="fr"]{
  .day[data-solstice="wintersolstice"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Hiver";
      }
    }
  }
  .day[data-solstice="vernalequinox"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Printemps";
      }
    }
  }
  .day[data-solstice="summersolstice"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Été";
      }
    }
  }
  .day[data-solstice="autumnalequinox"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Automne";
      }
    }
  }

}

html[lang="en"]{
  .day[data-solstice="wintersolstice"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Winter";
      }
    }
  }
  .day[data-solstice="vernalequinox"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Spring";
      }
    }
  }
  .day[data-solstice="summersolstice"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Summer";
      }
    }
  }
  .day[data-solstice="autumnalequinox"]{
    .solistice{
      @extend .color-red;
      &:before{
        content: "Autumn";
      }
    }
  }
}




// EVENTS IN CALENDAR

.more-info{
  text-indent: 3em;
  display: inline-block;
  @extend .underline;
}

.calendar-inside-info{
  margin: 2rem 0;
}

.day.event, .day.event-end, .day.event-start{
  .has-event{
    margin-left: 0.5em;
    @include font(icons);
    &:before{
      content:"\f104";
      @extend .color-red;
    }
  }
}
