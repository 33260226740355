// BORDER-BOX, RESET

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

* {
  margin: 0;
/*  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;*/
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;


}

main{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 1.25rem 1.25rem 0.9rem 1.25rem;
  @include mq(md){
    padding: 1.55rem 1.875rem
  }
}

main[data-id="home"]{
  padding: 0;
}

main[data-id="shop"]{
  padding: 0;
}

// __________________________________________________________ //

// GLOBAL SETTINGS

body {
  @include font;
  @include fs;
  color: var(--color-text);
  background-color: var(--color-bg);
}

html {
  font-size: 16px;
  background: var(--color-bg);
  color: var(--color-text);
}

a {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

ol {
  list-style-position: inside;
}

img {
  width: 100%;
}

// __________________________________________________________ //

// TYPE SETTINGS

strong,
b {
  font-weight: normal;
}

i,
em {
  @include font(mainitalic);
  font-style: normal !important;
}

.txt-center {text-align: center;}
.txt-left   {text-align: left;}
.txt-right {text-align: right;}

.txt-justify{
  text-align: justify;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  word-break:break-word;
  hyphens: unset;
  @include mq(md){
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
  }
}

.underline{
  text-decoration: underline;
  text-underline-offset: 0.1em;
}



h1{
    @include font(h1);
    @include fs(h1);
    font-weight: lighter;
    margin: 0;
}

h2{
  @include font(h2);
  @include fs(h2);
  font-weight: lighter;
  margin: 0;
}

h3{
  @include font(h3);
  @include fs(h3);
  letter-spacing: 0.09em;
  word-spacing: 0.1em;
  color: var(--color-red);
  text-align: center;
  text-transform: uppercase;
}

h4{
  @include font(h3);
  @include fs(h3);
  @extend h3;
  color: inherit;
  text-align: unset;
  text-transform: uppercase;
}

h5{
  @include font(h3);
  @include fs(h3);
}
