/* Some variables are “borrowed” from getkirby.com */

:root {
  --spacing-xs: 0.25rem;
  --spacing-s: 0.5rem;
  --spacing-m: 1rem;
  --spacing-l: 1.5rem;
  --spacing-xl: 3rem;

  --color-black: #000;
  --color-white: #fff;
  --color-red: #ff4100;
  --color-gray-100: #f7f7f7;
  --color-gray-200: #efefef;
  --color-gray-300: #ddd;
  --color-gray-400: #ccc;
  --color-gray-500: #999;
  --color-gray-600: #777;
  --color-gray-700: #595959;
  --color-gray-800: #3e3e3e;
  --color-gray-900: #292929;


  --text-xs: 0.733333em;
  --text-s: 1rem;
  --text-m: 1.4666666rem;
  --text-l: 3em;
  @include mq(md){
    --text-xs: 0.733333em;
    --text-s: 1rem;
    --text-m: 1.4666666em;
    --text-l: 3em;
  }

  --line-height: 1.2;

  --font-family-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 11.5555555555px;
  @include mq(md){
    font-size: 16px;
  }
  @include mq(lg){
    font-size: 20px;
  }
}
