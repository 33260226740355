main[data-id="checkout"] {
  .content-wrapper {
    grid-template-columns: repeat(12,1fr);
  }
}

#checkout-form{
  grid-column: 4/10;
  font-feature-settings: "lnum";
}

.checkout .cart{
  grid-column: 10/13;
  padding-right: 0;
  padding-left: 0;
  @include mq(md){
    padding-left: 1.875rem;
  }
}

.notice{
  grid-column: 1/12;
}





@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}



#checkout-form{
  gap: 0;
  grid-column-gap: var(--spacing-l);
  margin-bottom: 5rem;
}

.checkout .error {
  grid-column-start: span 12;
}

.checkout.is-loading {
  color: var(--color-gray-700);
}
.checkout button[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.checkout button[type="submit"]::after {
  content: '';
  display: none;
  width: 0.7rem;
  height: 0.7rem;
  margin-inline-start: var(--spacing-s);
  border-radius: 50%;
  border: 2px solid var(--color-white);
  border-bottom-color: transparent;
  animation: 1.5s rotate linear infinite;
}
.checkout.is-loading button[type="submit"]::after {
  display: block;
}

.notice{
  margin-top: 4rem;
  display: none;
}

#stripe-card{
  grid-column: span 2;
  margin-top: 1em;
  @include mq(md){
    grid-column: unset;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .checkout {
    grid-template-columns: 1fr auto;
    grid-auto-flow: dense;
    align-items: start;
  }

  .checkout .cart {
    position: sticky;
    top: var(--spacing-m);
    grid-row-end: span 2;
  }
}
