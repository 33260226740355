* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar{
  display: none;
}

*:focus {outline:0;}


html {
  font-family: var(--font-family-sans);
  line-height: var(--line-height);
  color: var(--color-black);
}
img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: var(--spacing-m);
  margin: 0 auto;
}
summary,
strong {
  font-weight: 600;
}

/* RESETS */

li {
  list-style: none;
}
a {
  color: currentColor;
}
button {
  font: inherit;
  background: none;
  border: 0;
  color: currentColor;
}
button:not(:disabled) {
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6{
  font-weight: normal !important;
}

/* HELPER CLASSES */
.color-gray-100 {
  color: var(--color-gray-100);
}
.color-gray-200 {
  color: var(--color-gray-200);
}
.color-gray-300 {
  color: var(--color-gray-300);
}
.color-gray-400 {
  color: var(--color-gray-400);
}
.color-gray-500 {
  color: var(--color-gray-500);
}
.color-gray-600 {
  color: var(--color-gray-600);
}
.color-gray-700 {
  color: var(--color-gray-700);
}
.color-gray-800 {
  color: var(--color-gray-800);
}
.color-gray-900 {
  color: var(--color-gray-900);
}
.color-red {
  color: var(--color-red);
}
.color-black{
  color: var(--color-black);
}

.text-xs {
  font-size: var(--text-xs);
  line-height: 1.33333333em;
}
.text-s {
  font-size: var(--text-s);
  line-height: 1.33333333em !important;
}
.text-m {
  font-size: var(--text-m);
  line-height: 1.333333em !important;
}
.text-l {
  font-size: var(--fs-h2);
  line-height: 1.08em;
  @include mq(md){
    font-size: var(--text-l);
  }
}
/*.text-xl {
  font-size: var(--text-xl);
}
.text-xxl {
  font-size: var(--text-xxl);
}
.text-3xl {
  font-size: var(--text-3xl);
}
.text-4xl {
  font-size: var(--text-4xl);
}
.text-5xl {
  font-size: var(--text-5xl);
}
.text-6xl {
  font-size: var(--text-6xl);
}*/

.stack-s > * + * {
  margin-top: var(--spacing-s);
}
.stack-m > * + * {
  margin-top: var(--spacing-m);
}

/* STANDARD COMPONENTS */
.blocks {
}
.text {
  line-height: 1.4;
}

textarea{
  padding-top: 0.1em;
  line-height: 1.2em;
}
/*.text > * {
  margin-block: 0;
}
.text > * + * {
  margin-block-start: var(--spacing-s);
}*/
.table td,
.table th {
  text-align: start;
  padding: var(--spacing-xs);
}

.button {
  @extend h3;
  text-transform: uppercase;
  display: inline-block;
  padding: 1rem;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid;
  color: black;
  text-align: center;
  background-color: white;
  min-width: 15em;
  &:hover{
    color: var(--color-red);
  };
}
.button[disabled] {
  color: var(--color-white);
  background-color: var(--color-gray-600);
}
.button-white {
  display: inline-block;
  padding: 0.5em;
  font-weight: 500;
  color: var(--color-black);
  background-color: var(--color-white);
}

.button-slim{
  @extend h3;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  padding: 0.12rem 0;
  font-weight: 500;
  border-bottom: 1px solid;
}

.error {
  color: var(--color-red);
}

.global-message {
  display: block;
  padding: var(--spacing-m);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-black);
}

.sticky-top{
  position: sticky;
  top: 0;
}


