.block-type-collapse{
  border-bottom: 1px solid;
  &:first-child{
    margin-top: 0;
  }
  &:last-of-type{
    margin-bottom: 2em;
  }
}

.collapsible {
  position: relative;
  color: inherit;
  cursor: pointer;
  background: transparent;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  @include padding(0.4 0);
  &:hover{
    color: var(--color-red);
  }
}

html[lang="en"]{
  .collapsible p{
    &:after{
      content:"Read more";
    }
  }
  .collapsible.active p{
    &:after{
      content:"Read less";
    }
  }
}

html[lang="fr"]{
  .collapsible p{
    &:after{
      content:"Lire plus";
    }
  }
  .collapsible.active p{
    &:after{
      content:"Lire moins";
    }
  }
}

/* Style the collapsible content. Note: hidden by default */
.content {
  max-height: 0;
  grid-column: span 3;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  @include mq(md){
    padding: 0 12.5%;
  }
  &.collapsed{
  }
}

.content-inner{
  @include padding(0.4 0 0.4 0);
  columns: 1;
  @include mq(md) {
    &[data-columns="one"] {
      columns: 1;
    }
    &[data-columns="two"] {
      columns: 2;
      column-fill: balance;
      padding-bottom: 0.6em;
    }
  }
  p{
    margin-bottom: 1em;
    &:last-of-type{
      margin-bottom: 0.2em;
    }
  }
  a{
    text-indent: 0em;
    display: inline-block;
    @include mq(md){
      text-indent: 0em;
    }
  }
  a.underline{
  }
  a + br + a.underline{
    text-indent: 0 !important;
  }
  a + a.underline{
    text-indent: 0 !important;
  }
}


.table{
  max-width: 100%;
  @include mq(md){
    max-width: 50%;
  }
  & + p{
    margin-top: 1em;
  }
  .row{
    display: grid;
    grid-template-areas: "a a";
    grid-auto-columns: 1fr 4em;
    @include mq(md){
      grid-auto-columns: 55% 4em;
    }
    p{
      margin-bottom: 0;
    }
  }
  a{
  }
}

.collapsible:after {
}

.collapsible.active:after {
}


// COLLAPSIBLE SLIM

.collapsible-slim-content.content{
    padding: 0;
  @include mq(md){
    max-height: unset !important;
    height: auto !important;
    overflow: auto;
  }
}

.collapsible-slim{
  padding: 0;
  &:hover{
    color: black;
  }
  @include mq(md){
    cursor: default;
    &:hover{
      color: inherit;
    }
  }
}

.collapsible.collapsible-slim{
  &:before{
    margin-right: 0.5em;
    content:"+";
    display: inline-block;
    width: 1em;
    @include mq(md){
      display: none;
    }
  }
}

.collapsible.collapsible-slim.active{
  &:before{
    height: 100%;
    margin-right: 0.5em;
    display: inline-block;
    width: 1em;
    content:"−";
    @include mq(md){
      display: none;
    }
  }
}

// COLLAPSIBLE CALENDAR

.collapsible-calendar.calendar-header{
  margin: 0;
  border-bottom: 1px solid;
  padding-top: 0.08rem;
  padding-bottom: 0.08rem;
}

.collapsible-calendar{
  position: static;
  padding: 0;
}
.calendar-content.content{
  padding: 0;
  @include mq(md){
    max-height: unset !important;
    height: auto !important;
    overflow: hidden;
  }
  .day:first-of-type{
    border-top: 0;
  }
}
