/* Customize the label (the container) */


.container {
  position: relative;
  padding-left: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.3em !important;
  display: grid !important;
  grid-template-columns: 0 min-content 1fr;
  align-items: center;
  justify-items: start;
}

/* Hide the browser's default checkbox */
.container input {
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  display: block;
  margin-right: 1rem;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: white;
  border: 1px solid black;
  position: relative;
  top: -0.025rem;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  @media (hover: hover) {
    background-color: black;
  }
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
