header{
  position: fixed;
  z-index: 99;
  width: 100%;
  padding: 0;
  background: white;
  &.has-menu-active{
    background: white !important;
    color: black !important;
  }
  @include mq(md){
    background: transparent;
    padding: 1.3rem 1.875rem;
  }
}

#home header{
  background: transparent;
}

header.light-theme{
  color: white;
  background: transparent;
}

.header a,
.header summary {
  text-decoration: none;
}

.header-nav {
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.header-nav .nav-item a{
  display: inline-block;
}

.header-nav .nav-item a.active{
  @extend .color-red;
}

.header-nav .nav-item a.site-title.active{
  color: inherit;
}

.header-nav .nav-item a:hover{
  @extend .color-red;
}
.header-nav .nav-item a.site-title:hover{
  @extend .color-red;
}

.header-nav .nav-item a.text-xs:hover{
  color: inherit;
  text-decoration: underline;
}

.header-nav .nav-item a.text-xs.active{
  color: inherit;
  text-decoration: underline;
}

.header-nav .nav-item{
  @include mq(md){
    grid-column: span 2;
    padding: 0;
    margin: 0;
    border: none;
  }
  grid-column: span 1;
  padding: 1rem 1.25rem;
  margin: 0 -1.25rem;
  border-bottom: 1px solid;
}

.header-nav .nav-item.shop{
  @include mq(md) {
    grid-column: span 4;
    display: grid;
    justify-content: center;
    align-self: center;
    order: unset;
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-column: span 2;
  order: -1;
}

.header-nav .nav-item.shop[data-cart="has-cart"]{
  .toggle-cart{
    display: none;
  }
  @include mq(md) {
    grid-column-end: span 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    text-align: center;
    width: 100%;
    gap: 2.8em;
    .details-cart {
      display: flex;
    }
    > a {
      text-align: right;
      display: inline;
      justify-self: end;
    }
    .toggle-cart, .link-cart {
      justify-self: start;
    }
    .toggle-cart{
      display: block;
    }
  }
}



.header-nav .nav-item.moissons{
  @include mq(md){
    text-align: right;
  }
}

.header-nav .nav-item.courrier{
  @include mq(md) {
    text-align: right;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: baseline;
  }
}

.logo {
  grid-row-end: span 2;
  align-self: end;
  font-weight: 600;
  text-decoration: none;
  padding: 1rem;
}

.lang-switch {
  @include mq(md){
    display: block;
  }
  white-space: nowrap;
  text-align: center;
  position: relative;
  top: -0.1rem;
  display: none;

    h4{
      display: inline-block;
    }
    h4:first-of-type{
      margin-right: 0.4em;
    }
}


// MOBILE MENU


.menu-toggle{
  padding: 1rem 1.25rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 1;
  grid-column: span 6;
  padding-left: 0.5rem;
  &.is-single .toggler{
    width: 100%;
  }
  @include mq(md){
    display: none;
  }
  .toggler a{
    @include font(h3);
    @include fs(h3);
    display: flex;
    justify-content: space-between;
    &:after{
      margin-left: 0.5em;
      @include font(icons);
      @include fs(icons);
      content: "\f101";
    }
  }
}

.has-menu-active .toggler a:after{
  content: "\f102";
}

.nav-inner{
  grid-column: 1/13;
  grid-column-gap: 1rem;
  padding: 0 1.25rem;
  order: 2;
  height: 0;
  overflow: hidden;
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content min-content min-content 1fr;
  &.is-active{
    border-top: 1px solid;
    height: calc((var(--vh) - var(--hh)) * 100);
  }
  @include mq(md){
    height: auto;
    padding: 0;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 0rem;
    grid-column: 3/13;
    background: transparent;
    display: grid;
    order: unset;
  }
}

.nav-item.home{
  grid-column: span 6;
  padding: 1rem 1.25rem;
  margin: 0;
  border-bottom: none;
  @include mq(md){
    grid-column: span 2;
    padding: 0;
  }
}

.nav-item.instagram{
  display: block;
  @include mq(md){
    display: none;
  }
}

.nav-inner-container{
  display: grid;
  grid-column: span 2;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-template-rows: min-content 1fr min-content;
  padding: 1rem 0 0;
  height: 100%;
  @include mq(md){
    display: none;
  }
}

.nav-inner-container .toggle-cart-xs{
  grid-column: 2/3;
  align-self: start;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  left: - 1rem;
  width: calc(100% + 1rem);
}

.category-menu{
  display: flex;
  flex-direction: column;
  line-height: 1.65em;
  @include mq(md){
    display: none;
  }
}

.calendar-info{
  align-self: end;
}

.nav-inner-container .logo{
  grid-column: 1;
  grid-row-end: unset;
  width: 7rem;
  padding-left: 0;
}

.footer-inner{
  padding: 1rem 1.25rem;
  margin: 0 -1.25rem;
  border-top: 1px solid;
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-self: end;
  .footer-menu-item:first-of-type{
    display: none;
  }
}

header .calendar-info{
  .swiper-v-trigger:hover{
    color: inherit;

  }
}

.calendar-info{
  cursor: pointer;
}

.calendar-info .lang-switch{
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
  a.active{
    text-decoration: underline;
  }
}
