.content-wrapper{
  padding-top: 6.8rem;
}

main{
  padding-top: calc(var(--hh) * 100) !important;
  &[data-id="home"]{
    padding-top: 0 !important;
  }
  @include mq(md){
    padding-top: unset;
  }
}
