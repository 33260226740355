/*
————————————————————————————————————
  GENERAL
————————————————————————————————————
*/

.swiper-container {
  position: relative;
  height: 100% !important;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s linear;

  &.hide {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}

.swiper-wrapper {
  height: 100%;
  width: 100%;
}

.swiper-slide {
  height: 100% !important;
  width: 100%;
  display: flex;

  picture {
    display: block;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &.no-flex {
    display: block;
  }

}

.swiper-slide.swiper-lazy {
  opacity: 0;
}

.swiper-slide.swiper-lazy-loaded{
    opacity: 1;
    transition: opacity $transition-duration-default;
}


.swiper-slide picture:first-of-type {
  display: none;
  @include mq(md) {
    display: block;
  }
}

.swiper-slide picture {
  width: 100%;
  @include mq(md) {
    width: 50%;
  }
}

.swiper-slide.no-flex footer{
  @include mq(md){
    margin-bottom: 1.55rem;
  }
  margin-bottom: 0.95rem;

}

/*
————————————————————————————————————
  BUTTONS
————————————————————————————————————
*/

.swiper-button {
  position: absolute;
  height: 100%;
  cursor: pointer;
  width: 100%;
  z-index: 99;

  &.swiper-prev {
    left: 0;
  }

  &.swiper-next {
    right: 0;
  }

  @include mq(sm) {
    width: 100%;
  }

}


/*
————————————————————————————————————
  HOME CAROUSEL
————————————————————————————————————
*/

.swiper-slide.no-flex {
  .grid {
    padding: 0 1.25rem;
    overflow: scroll;
    height: 100%;
    @include mq(md){
      padding: 0 1.875rem;
    }
  }

  .content-wrapper {
    grid-column: 3/11;
  }
}


/*
————————————————————————————————————
  PRODUCT CAROUSEL
————————————————————————————————————
*/

.swiper-button-next, .swiper-button-prev{
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 999999999;
  opacity: 0.5;
}


.swiper-button-next{
  right: 0;
}

.swiper-product {
  flex: 1;
  height: 100%;

  .swiper-wrapper {
    height: 100%;
    @include mq(md) {
      align-items: center;
      justify-content: center;
    }
  }

  .swiper-slide {
    background-color: white;
    box-sizing: border-box;
    height: auto !important;
    width: 100% !important;
    padding: 3.125rem;
    display: flex;
    align-items: center;
    @include mq(md) {
      padding: 0;
    }

    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &:first-of-type {
        display: block;
      }

      &.landscape {
        height: auto;
        align-self: center;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}



