main{
  .content-wrapper{
    grid-column: 5/9;
    grid-template-rows: min-content;
  }
}

.order .cart {
  background-color: var(--color-white);
}

.print{
  button{
    width: 100%;
  }
}


.invoice-adress{
  margin-top: 3rem;
  grid-column: 6/13;
}

.order-review{
  padding-top: 0.4em;
  border-top: 1px solid;
  padding-left: 50%;
}
