.custom-select {
  position: relative;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  position: relative;
  background-color: white;
  @extend h4;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  @include font(icons);
  @include fs(icons);
  content: "\f101";
  position: absolute;
  right: 0.9em;
  top: 0.15em;
  width: 0;
  height: 0;
/*  border: 6px solid transparent;
  border-color: var(--color-red) transparent transparent transparent;*/
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  content: "\f102";
}

.select-selected.select-arrow-active{
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: var(--color-red);
  padding: 0.25em 0 0.2em 0;
  cursor: pointer;
  border-bottom: 1px solid var(--color-red);
}

.select-items div{
  color: black;
  border-bottom: 1px solid var(--color-black);
  margin-top: -1px;
  @extend h4;
}

/* Style items (options): */
.select-items {
  position: absolute;
  width: calc(100% + 2px);
  background-color: white;
  top: 100%;
  left: 0px;
  right: 0;
  z-index: 99;
  max-height: 15.5em;
  overflow-y: scroll;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  color: var(--color-red);
  border-color: red;
}
