main[data-id="retailers"] {
  .content-wrapper {
    grid-column: 5 / 9;
  }
}

main[data-id="retailers"] {
  .cover-image{
    margin-bottom: 3rem;
  }

  h4{
    margin-bottom: 1.1rem;
  }
  p.text-m{
    margin-bottom: 0.6rem;
  }
  p.text-xs{
    margin-bottom: 2.8rem;
  }

  h4:last-of-type{
    margin-top: 3.2rem;
  }
}
