main[data-id=shop]{
  footer{
    padding: 1.55rem 1.875rem;
    padding-top: 8rem;
  }
}

.product-grid{
  display: grid;
}

.category-filter{
  display: none;
  position: fixed;
  z-index: 9999999;
  padding: 1em;
  bottom: 0;
  gap: 1em;
  a:hover{
    @extend .color-red;
  }
  a.active{
    @extend .color-red;
  }
  @include mq(md){
    display: flex;
  }
}

section.category{
  opacity: 0;
  transition: opacity 0.5s;
  height: 0;
  overflow: hidden;
}

section.category.show{
  height: auto;
  overflow: auto;
  opacity: 1;
  transition: opacity 0.5s;
}
