.field[data-type="select"]{
  label{
    padding: 0;
  }
  margin-top: 1.05em;
}

.country-select{
  @include font(h3);
  @include fs(h3);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ss-main{
  padding-left: 1em;
}
.ss-main .ss-single-selected{
  border-radius: 0;
  border: none;
  color: black;
  padding: 0;
  padding-top: 1px;
  height: auto;
}
.ss-content .ss-search input{
  border: none;
  border-radius: 0;
  height: auto;
  padding: 0;
  -webkit-appearance: none;
  outline: none;
  text-transform: uppercase;
}
.ss-content .ss-search input:focus{
  box-shadow: none;
}
.ss-content .ss-search{
  padding: 0;
  margin-bottom: 1em;
}
.ss-content{
  border: 0;
  color: black;
  padding: 1em 1em 0;
  margin: 0 -1em !important;
  transition: none;
}
.ss-content .ss-list{
  max-height: 19.5em;
}
.ss-content .ss-list .ss-option{
  padding: 0.25em 0 0.2em 0;
  border-bottom: 1px solid;
  &:hover{
    background: transparent;
    color: var(--color-red);
    border-color: var(--color-red);
  }
}

.ss-content .ss-list .ss-option.ss-disabled{
  background: transparent;
  color: var(--color-red);
  border-color: var(--color-red);
  &:hover{
    background: transparent;
    color: var(--color-red);
    border-color: var(--color-red);
    cursor: default;
  }
}

.ss-main .ss-single-selected .placeholder{
  align-self: end;
  line-height: 0.9em;
  &:after{
    content: "";
    width: 100%;
    border-bottom: 1px solid;
    align-self: end;
    position: relative;
    top: 0;
    margin-left: 1em;
    margin-right: 1.5em;
  }
}

.ss-main .ss-single-selected .ss-arrow{
  margin: 0;
  position: absolute;
  right: -0.2em;
  bottom: -0.3em;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-up, .ss-main .ss-single-selected .ss-arrow span.arrow-down{
  transform: none;
  border: none;
  padding: 0;
  margin: 0;
}
.ss-main .ss-single-selected .ss-arrow span.arrow-up:before{
  @include font(icons);
  @include fs(icons);
  content: "\f102";
}
.ss-main .ss-single-selected .ss-arrow span.arrow-down:before{
  @include font(icons);
  @include fs(icons);
  content: "\f101";
}
